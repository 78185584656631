.Search {
  display: block;
  height:auto;
  margin-top:2rem;
  padding: 0 2rem;
}

.Search input {
  -webkit-appearance: none;
  display:block;
  background:#fff;
  border:none;
  border-bottom: .1rem solid #eeefee;
  box-shadow:none;
  font-family: 'Assistant', Sans-Serif;
  font-size: 1.4rem;
  font-weight: 400;
  height:4.1rem;
  letter-spacing: .01em;
  line-height:4rem;
  margin:0 auto;
  max-width:96rem;
  outline:none;
  padding:0 3rem;
  text-align:center;
  transition: background .25s ease-in-out;
  width:100%;
}

.Search input:focus,
.Search input:active {
  background: #fff;
  outline:none;
}

@media screen and (min-width:768px) {
  .Search input {
    font-size: 1.8rem;
    height: 6.1rem;
    line-height:6rem;
  }
}

@media screen and (min-width:1280px) {
  .Search input {
    font-size: 2.2rem;
    height: 8.1rem;
    line-height:8rem;
    max-width:120rem;
  }
}

.Search .tags {
  list-style: none;
  line-height: 2.4rem;
  margin:1.5rem auto 0 auto;
  max-width:96rem;
  padding:0;
  width:100%;
}

@media screen and (min-width:1280px) {
  .Search .tags {
    max-width:120rem;
  }
}

.Search .tags .intro {
  display:inline-block;
  margin-right: .5rem;
}

.Search .tags .intro:after {
  content:":";
}

.Search .tags .tag {
  display: inline-block;
  font-family: 'Assistant', Sans-Serif;
  font-size: 1.4rem;
  letter-spacing:.05em;
  list-style: none;
  margin:0 .5rem 0 0;
  text-transform: uppercase;
}

.Search .tags .tag a {
  background: #f5ff6b;
  border: .1rem solid #f2ff3f;
  border-radius: 1rem;
  box-shadow:none;
  display: inline-block;
  height: 2.2rem;
  line-height: 2rem;
  padding: 0 1rem;
}