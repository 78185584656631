@keyframes LoadingLoop {
  from {
    transform: translate3d(0,0,0) rotate(0deg);
  }

  to {
    transform: translate3d(0,0,0) rotate(360deg);
  }
}

.Loading {
  background: rgba(251,251,251,.75);
  bottom:0;
  display: none;
  height: auto;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity .25s ease-in-out;
  width: auto;
  z-index: 9999;
}

.Loading.ready {
  display:block;
}

.Loading.visible {
  opacity: 1;
}

.Loading .inside {
  display: block;
  height: 100vh;
  position: relative;
  width: 100vw;
}

.Loading .spinner {
  animation-duration: .5s;
  animation-iteration-count: infinite;
  animation-name: LoadingLoop;
  animation-timing-function: linear;
  background: #f5ff6b;
  border-radius:50%;
  bottom: 1rem;
  display: block;
  height: 4rem;
  left: 1rem;
  padding: .5rem;
  position: absolute;
  transform: translate3d(0,0,0);
  transform-origin: 50% 50%;
  width: 4rem;
}

.Loading svg {
  display: block;
  height: 100%;
  width: 100%;
}

.Loading svg path {
  fill: #444444;
}