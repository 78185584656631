html {
  font-size: 62.5%;
}

body {
  background: #fbfbfb;
  border: 0;
  color: #444;
  font-family: 'Playfair Display', Serif;
  font-size: 1.6rem;
  line-height: 1.25;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

a {
  border-bottom: 1px solid #f2ff3f;
  box-shadow: inset 0 -0.75rem 0 0 #f5ff6b;
  color:#444;
  text-decoration: none;
  transition: background .25s ease-in-out;
}

a:active,
a:hover {
  background: #f5ff6b;
}