.offline {
  display:block;
  max-width: 96rem;
  margin:0 auto;
  padding: 0 2rem;
  width: 100%;
}

@media screen and (min-width:1024px) {
  .offline {
    padding:0;
  }
}

@media screen and (min-width:1280px) {
  .offline {
    max-width:120rem;
  }
}

.offline p {
  display: block;
  font-family: 'Assistant', Sans-Serif;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: .2em;
  text-align: center;
  text-transform: uppercase;
}