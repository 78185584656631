.List {
  display:block;
  max-width: 96rem;
  margin:0 auto;
  padding: 0 2rem;
  width: 100%;
}

@media screen and (min-width:1024px) {
  .List {
    padding:0;
  }
}

@media screen and (min-width:1280px) {
  .List {
    max-width:120rem;
  }
}

.List ul,
.List li {
  list-style:none;
  margin:0;
  padding:0;
}

.List li {
  margin-bottom: 5rem;
}

.List h1 {
  font-family: 'Assistant', Sans-Serif;
  font-size: 1.8rem;
  font-weight: 400;
  margin: 5rem 0;
  text-transform: uppercase;
}

@media screen and (min-width:768px) {
  .List h1 {
    font-size: 2rem;
    margin: 8rem 0;
  }
}

.List h1 em {
  background: #f5ff6b;
  box-shadow: .2rem 0 .1rem 0 #f5ff6b, -.2rem 0 .1rem 0 #f5ff6b;
  display: inline-block;
  font-size: 80%;
  font-style: normal;
  text-transform: uppercase;
}

.List h1 button.clear {
  background: transparent;
  border: 0;
  border-radius:.9rem;
  display: inline-block;
  font-family: 'Assistant', Sans-Serif;
  font-size: 1rem;
  font-weight: 400;
  height: 1.8rem;
  line-height:1.8rem;
  letter-spacing: .1rem;
  margin: 0 0 0 .5rem;
  outline: none;
  text-transform: uppercase;
  vertical-align: middle;
}

.List h1 button.clear:hover,
.List h1 button.clear:active {
  background:rgb(236, 236, 236);
}


.List h1 button.toggle {
  background: transparent;
  border: 0;
  display: block;
  float: right;
  margin: 0;
  outline: none;
}

.List h1 button svg {
  height: 2.2rem;
  width: 2.2rem;
}

.List h1 button svg path {
  stroke: #444;
}

.List h1 button svg rect {
  fill: #444;
}

@media screen and (min-width:768px) {
  .List ul.cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -2rem;
  }
}

@media screen and (min-width:768px) {
  .List ul.cards > li {
    flex: 0 0 50%;
    padding: 0 2rem;
  }
}

@media screen and (min-width:1024px) {
  .List ul.cards > li {
    flex: 0 0 33.333333%;
  }
}

@media screen and (min-width:1280px) {
  .List ul.cards li {
    flex: 0 0 25%;
  }
}