.Article {
  display: block;
  max-width: 90rem;
  padding-bottom: 5rem;
  position: relative;
}

.Article:after {
  background:#444;
  bottom:0;
  content:"";
  display:block;
  height:.1rem;
  left:0;
  position: absolute;
  width: 50%;
}

.Article h1 {
  font-family:'Playfair Display', Serif;
  font-size: 2.8rem;
  font-weight:900;
  letter-spacing: .0000002em;
  margin:0;
  text-transform: none;

  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.Article h1 span {
  background: #fbfbfb;
  box-shadow: 0.2rem 0 0.01rem 0 #fbfbfb, -0.2rem 0 0.01rem 0 #fbfbfb;
}

.Article > a:hover h1 span,
.Article > a:active h1 span {
  background: #f5ff6b;
  box-shadow: 0.2rem 0 0.01rem 0 #f5ff6b, -0.2rem 0 0.01rem 0 #f5ff6b;
}

.Article figure {
  display: none;
}

.Article p {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.Article .tags {
  list-style: none;
  line-height: 2.4rem;
  margin:0;
}

.Article .tags .tag {
  display: inline-block;
  font-family: 'Assistant', Sans-Serif;
  font-size: 1.4rem;
  letter-spacing:.05em;
  list-style: none;
  margin:0 .5rem 0 0;
  text-transform: uppercase;
}

.Article .tags .tag a {
  background: #f5ff6b;
  border: .1rem solid #f2ff3f;
  border-radius: 1rem;
  box-shadow:none;
  display: inline-block;
  height: 2.2rem;
  line-height: 2rem;
  padding: 0 1rem;
}

.Article .tags .tag.additional {
  display: none;
}

.Article .tags .tag a.show,
.Article .tags .tag a.hide {
  background: transparent;
  border-color: #ccc;
  color: #999;
}

.Article .tags.expanded .tag.with-switch .show {
  display: none;
}

.Article .tags.expanded .tag.additional {
  display: inline-block;
}

.cards .Article figure {
  background:#eee;
  border: 0;
  display: block;
  height: 14rem;
  margin:0;
  padding:0;
  width: 100%;
  z-index:8;
}

.cards .Article figure img {
  border: 0;
  display: block;
  height: 14rem;
  object-fit: cover;
  width: 100%;
}

.cards .Article figure + h1 {
  margin: -7rem 1rem 0 1rem;
  min-height: 9rem;
}

.cards .Article h1 {
  display: block;
  margin: 0 1rem 0 1rem;
  padding: 1rem;
  z-index:9;
}

.cards .Article footer,
.cards .Article p {
  padding: 0 2rem;
}

.cards .Article:after {
  content:none;
  display:none;
}