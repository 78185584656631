html {
  font-size: 62.5%;
}

body {
  background: #fbfbfb;
  border: 0;
  color: #444;
  font-family: 'Playfair Display', Serif;
  font-size: 1.6rem;
  line-height: 1.25;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

a {
  border-bottom: 1px solid #f2ff3f;
  box-shadow: inset 0 -0.75rem 0 0 #f5ff6b;
  color:#444;
  text-decoration: none;
  transition: background .25s ease-in-out;
}

a:active,
a:hover {
  background: #f5ff6b;
}
.offline {
  display:block;
  max-width: 96rem;
  margin:0 auto;
  padding: 0 2rem;
  width: 100%;
}

@media screen and (min-width:1024px) {
  .offline {
    padding:0;
  }
}

@media screen and (min-width:1280px) {
  .offline {
    max-width:120rem;
  }
}

.offline p {
  display: block;
  font-family: 'Assistant', Sans-Serif;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: .2em;
  text-align: center;
  text-transform: uppercase;
}
.Header {
  background: #fff;
  display:block;
  height: 5.1rem;
  overflow: hidden;
  position: relative;
}

@media screen and (min-width:768px) {
  .Header {
    height: 7.5rem;
  } 
}

@media screen and (min-width:1280px) {
  .Header {
    height: 10rem;
  } 
}

.Header h1 {
  display: block;
  font-family: 'Assistant', Sans-Serif;
  font-size: 1.2rem;
  font-weight: 400;
  height: 5rem;
  letter-spacing: .2em;
  line-height: 5rem;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

@media screen and (min-width:768px) {
  .Header h1 {
    font-size: 1.6rem;
    height: 7.5rem;
    line-height: 7.5rem;
  } 
}

@media screen and (min-width:1280px) {
  .Header h1 {
    height: 10rem;
    line-height: 10rem;
  } 
}

.Header h1 a,
.Header h1 a:hover,
.Header h1 a:active {
  background: transparent;
  box-shadow:none;
  border-bottom:0;
  color:#444;
}

.Header h1 strong {
  font-weight: 900;
}

@media screen and (min-width:768px) {

}
.Search {
  display: block;
  height:auto;
  margin-top:2rem;
  padding: 0 2rem;
}

.Search input {
  -webkit-appearance: none;
  display:block;
  background:#fff;
  border:none;
  border-bottom: .1rem solid #eeefee;
  box-shadow:none;
  font-family: 'Assistant', Sans-Serif;
  font-size: 1.4rem;
  font-weight: 400;
  height:4.1rem;
  letter-spacing: .01em;
  line-height:4rem;
  margin:0 auto;
  max-width:96rem;
  outline:none;
  padding:0 3rem;
  text-align:center;
  transition: background .25s ease-in-out;
  width:100%;
}

.Search input:focus,
.Search input:active {
  background: #fff;
  outline:none;
}

@media screen and (min-width:768px) {
  .Search input {
    font-size: 1.8rem;
    height: 6.1rem;
    line-height:6rem;
  }
}

@media screen and (min-width:1280px) {
  .Search input {
    font-size: 2.2rem;
    height: 8.1rem;
    line-height:8rem;
    max-width:120rem;
  }
}

.Search .tags {
  list-style: none;
  line-height: 2.4rem;
  margin:1.5rem auto 0 auto;
  max-width:96rem;
  padding:0;
  width:100%;
}

@media screen and (min-width:1280px) {
  .Search .tags {
    max-width:120rem;
  }
}

.Search .tags .intro {
  display:inline-block;
  margin-right: .5rem;
}

.Search .tags .intro:after {
  content:":";
}

.Search .tags .tag {
  display: inline-block;
  font-family: 'Assistant', Sans-Serif;
  font-size: 1.4rem;
  letter-spacing:.05em;
  list-style: none;
  margin:0 .5rem 0 0;
  text-transform: uppercase;
}

.Search .tags .tag a {
  background: #f5ff6b;
  border: .1rem solid #f2ff3f;
  border-radius: 1rem;
  box-shadow:none;
  display: inline-block;
  height: 2.2rem;
  line-height: 2rem;
  padding: 0 1rem;
}
.List {
  display:block;
  max-width: 96rem;
  margin:0 auto;
  padding: 0 2rem;
  width: 100%;
}

@media screen and (min-width:1024px) {
  .List {
    padding:0;
  }
}

@media screen and (min-width:1280px) {
  .List {
    max-width:120rem;
  }
}

.List ul,
.List li {
  list-style:none;
  margin:0;
  padding:0;
}

.List li {
  margin-bottom: 5rem;
}

.List h1 {
  font-family: 'Assistant', Sans-Serif;
  font-size: 1.8rem;
  font-weight: 400;
  margin: 5rem 0;
  text-transform: uppercase;
}

@media screen and (min-width:768px) {
  .List h1 {
    font-size: 2rem;
    margin: 8rem 0;
  }
}

.List h1 em {
  background: #f5ff6b;
  box-shadow: .2rem 0 .1rem 0 #f5ff6b, -.2rem 0 .1rem 0 #f5ff6b;
  display: inline-block;
  font-size: 80%;
  font-style: normal;
  text-transform: uppercase;
}

.List h1 button.clear {
  background: transparent;
  border: 0;
  border-radius:.9rem;
  display: inline-block;
  font-family: 'Assistant', Sans-Serif;
  font-size: 1rem;
  font-weight: 400;
  height: 1.8rem;
  line-height:1.8rem;
  letter-spacing: .1rem;
  margin: 0 0 0 .5rem;
  outline: none;
  text-transform: uppercase;
  vertical-align: middle;
}

.List h1 button.clear:hover,
.List h1 button.clear:active {
  background:rgb(236, 236, 236);
}


.List h1 button.toggle {
  background: transparent;
  border: 0;
  display: block;
  float: right;
  margin: 0;
  outline: none;
}

.List h1 button svg {
  height: 2.2rem;
  width: 2.2rem;
}

.List h1 button svg path {
  stroke: #444;
}

.List h1 button svg rect {
  fill: #444;
}

@media screen and (min-width:768px) {
  .List ul.cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -2rem;
  }
}

@media screen and (min-width:768px) {
  .List ul.cards > li {
    flex: 0 0 50%;
    padding: 0 2rem;
  }
}

@media screen and (min-width:1024px) {
  .List ul.cards > li {
    flex: 0 0 33.333333%;
  }
}

@media screen and (min-width:1280px) {
  .List ul.cards li {
    flex: 0 0 25%;
  }
}
.Article {
  display: block;
  max-width: 90rem;
  padding-bottom: 5rem;
  position: relative;
}

.Article:after {
  background:#444;
  bottom:0;
  content:"";
  display:block;
  height:.1rem;
  left:0;
  position: absolute;
  width: 50%;
}

.Article h1 {
  font-family:'Playfair Display', Serif;
  font-size: 2.8rem;
  font-weight:900;
  letter-spacing: .0000002em;
  margin:0;
  text-transform: none;

  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.Article h1 span {
  background: #fbfbfb;
  box-shadow: 0.2rem 0 0.01rem 0 #fbfbfb, -0.2rem 0 0.01rem 0 #fbfbfb;
}

.Article > a:hover h1 span,
.Article > a:active h1 span {
  background: #f5ff6b;
  box-shadow: 0.2rem 0 0.01rem 0 #f5ff6b, -0.2rem 0 0.01rem 0 #f5ff6b;
}

.Article figure {
  display: none;
}

.Article p {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.Article .tags {
  list-style: none;
  line-height: 2.4rem;
  margin:0;
}

.Article .tags .tag {
  display: inline-block;
  font-family: 'Assistant', Sans-Serif;
  font-size: 1.4rem;
  letter-spacing:.05em;
  list-style: none;
  margin:0 .5rem 0 0;
  text-transform: uppercase;
}

.Article .tags .tag a {
  background: #f5ff6b;
  border: .1rem solid #f2ff3f;
  border-radius: 1rem;
  box-shadow:none;
  display: inline-block;
  height: 2.2rem;
  line-height: 2rem;
  padding: 0 1rem;
}

.Article .tags .tag.additional {
  display: none;
}

.Article .tags .tag a.show,
.Article .tags .tag a.hide {
  background: transparent;
  border-color: #ccc;
  color: #999;
}

.Article .tags.expanded .tag.with-switch .show {
  display: none;
}

.Article .tags.expanded .tag.additional {
  display: inline-block;
}

.cards .Article figure {
  background:#eee;
  border: 0;
  display: block;
  height: 14rem;
  margin:0;
  padding:0;
  width: 100%;
  z-index:8;
}

.cards .Article figure img {
  border: 0;
  display: block;
  height: 14rem;
  object-fit: cover;
  width: 100%;
}

.cards .Article figure + h1 {
  margin: -7rem 1rem 0 1rem;
  min-height: 9rem;
}

.cards .Article h1 {
  display: block;
  margin: 0 1rem 0 1rem;
  padding: 1rem;
  z-index:9;
}

.cards .Article footer,
.cards .Article p {
  padding: 0 2rem;
}

.cards .Article:after {
  content:none;
  display:none;
}
@-webkit-keyframes LoadingLoop {
  from {
    -webkit-transform: translate3d(0,0,0) rotate(0deg);
            transform: translate3d(0,0,0) rotate(0deg);
  }

  to {
    -webkit-transform: translate3d(0,0,0) rotate(360deg);
            transform: translate3d(0,0,0) rotate(360deg);
  }
}

@keyframes LoadingLoop {
  from {
    -webkit-transform: translate3d(0,0,0) rotate(0deg);
            transform: translate3d(0,0,0) rotate(0deg);
  }

  to {
    -webkit-transform: translate3d(0,0,0) rotate(360deg);
            transform: translate3d(0,0,0) rotate(360deg);
  }
}

.Loading {
  background: rgba(251,251,251,.75);
  bottom:0;
  display: none;
  height: auto;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity .25s ease-in-out;
  width: auto;
  z-index: 9999;
}

.Loading.ready {
  display:block;
}

.Loading.visible {
  opacity: 1;
}

.Loading .inside {
  display: block;
  height: 100vh;
  position: relative;
  width: 100vw;
}

.Loading .spinner {
  -webkit-animation-duration: .5s;
          animation-duration: .5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: LoadingLoop;
          animation-name: LoadingLoop;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #f5ff6b;
  border-radius:50%;
  bottom: 1rem;
  display: block;
  height: 4rem;
  left: 1rem;
  padding: .5rem;
  position: absolute;
  -webkit-transform: translate3d(0,0,0);
          transform: translate3d(0,0,0);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  width: 4rem;
}

.Loading svg {
  display: block;
  height: 100%;
  width: 100%;
}

.Loading svg path {
  fill: #444444;
}
