.Header {
  background: #fff;
  display:block;
  height: 5.1rem;
  overflow: hidden;
  position: relative;
}

@media screen and (min-width:768px) {
  .Header {
    height: 7.5rem;
  } 
}

@media screen and (min-width:1280px) {
  .Header {
    height: 10rem;
  } 
}

.Header h1 {
  display: block;
  font-family: 'Assistant', Sans-Serif;
  font-size: 1.2rem;
  font-weight: 400;
  height: 5rem;
  letter-spacing: .2em;
  line-height: 5rem;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

@media screen and (min-width:768px) {
  .Header h1 {
    font-size: 1.6rem;
    height: 7.5rem;
    line-height: 7.5rem;
  } 
}

@media screen and (min-width:1280px) {
  .Header h1 {
    height: 10rem;
    line-height: 10rem;
  } 
}

.Header h1 a,
.Header h1 a:hover,
.Header h1 a:active {
  background: transparent;
  box-shadow:none;
  border-bottom:0;
  color:#444;
}

.Header h1 strong {
  font-weight: 900;
}

@media screen and (min-width:768px) {

}